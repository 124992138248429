import React from "react";
import styled from "styled-components";
import { basePath } from "../lib/config";
import MediaObject from "./MediaObject";
import useMq, { imgSizeMap, boxPaddingMap } from "../hooks/mediaQuery";
import iconsMap from "../lib/activityIcons";
import MD from "react-markdown";
import Box from "./Box";
import ExternalLink from './ExternalLink';

const Wrapper = styled.div`
  background-color: white;

  .activity {
    &:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.05);
    }
    .MediaObject {
      max-width: 1000px;
      margin: auto;
    }
  }
`;

const Component = ({ className, data: activities = [] }) => {
  const breakPoint = useMq();
  const classes = ["HomeContent"];
  className && classes.push(className);

  return (
    <Wrapper className={classes.join(" ")}>
      <div className="activities">
        {activities.map((p, index) => {
          const content = (
            <div>
              <MD source={p.i18n.description} />
              {p.i18n.link.map(l => (
                <ExternalLink key={l.url} href={l.url} label={l.label} target="_blank" />
              ))}
            </div>
          );
          const props = {
            image: basePath + p.Pictures[0].formats[imgSizeMap[breakPoint]].url,
            title: p.i18n.title,
            content,
            reverse: index % 2 !== 0,
            icon: iconsMap[p.Icon],
            pictureCut: index < activities.length - 1 ? "organic" : "default"
          };
          return (
            <Box p={boxPaddingMap[breakPoint]} className="activity" key={p.id} id={p.id}>
              <MediaObject {...props} />
            </Box>
          );
        })}
      </div>
    </Wrapper>
  );
};

Component.displayName = "HomeContent";

export default Component;
