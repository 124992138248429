import React from "react";
import styled from "styled-components";
import useMQ, { imgSizeMap, breakpointsMap } from "../hooks/mediaQuery";

import { useHero } from "../lib/strapi";
import useLang from "../hooks/i18n";
import { basePath } from "../lib/config";


const Wrapper = styled.div`
  position: relative;

  .titleWrapper {
    position: relative;
    height: 100vh;

    .logo {
      max-width: 90%;
      margin: 0 auto 2rem;
    }
    h1 {
      margin: 0.5em 0 1em;
      line-height: 1;
      color: #476901;
      text-align: center;
      font-weight: 600;
      strong {
        color: #db9602;
      }
      svg {
        width: 1em;
        height: 1em;
        vertical-align: middle;
        color: #db9602;
        path {
          fill: currentColor;
        }
      }
    }

    h2 {
      text-align: center;
      color: #476901;
    }
  }

  .title {
    color: white;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 2rem 2rem 12rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    span {
      display: block;
    }
  }

  &.compact {
    .titleWrapper {
      height:auto;
    }
    .title {
      position:static;
      padding: 2rem 2rem 7rem;
    }

    h2 {
      display: none;
    }
  }

  @media screen and (${breakpointsMap.sm}) {
    .titleWrapper {
      display: flex;
    }
    .title {
      display: flex;
      padding: 4rem 4rem 12rem;
      flex-direction: column;
      justify-content: flex-end;
      position: static;
      max-width: 60vw;
    }
  }

  @media screen and (${breakpointsMap.md}) {
    .title {      
      max-width: 600px;
    }
  }
`;

const TitleComponent = ({logo}) => (
  <img alt='Logo' className="logo" src={logo} />  
);

const Component = ({ className, compact = false }) => {
  const lang = useLang();
  const hero = useHero() || {};
  const mq = useMQ();
  
  const logo = hero.logo && basePath + hero.logo.formats[imgSizeMap[mq]].url;

  const classes = [""];
  className && classes.push(className);
  compact && classes.push('compact');

  return (
    <Wrapper className={classes.join(" ")} >
      <div className="titleWrapper">
        <div className="title">
          <TitleComponent logo={logo} />
          <h2>{hero[lang]}</h2>
          {/* {mq != "xs" && <h2>{hero[lang]}</h2>} */}
        </div>
      </div>
    </Wrapper>
  );
};

Component.displayName = "";

export default Component;
