import React from "react";
import styled from "styled-components";
import Article from 'components/Article';
import useMq, { imgSizeMap } from "../hooks/mediaQuery";
import { basePath } from "lib/config.js";

const Wrapper = styled.div``;

const Component = ({ className, data:content }) => {
  const breakPoint = useMq();

  const classes = ["Contacts"];
  className && classes.push(className);

  const contacts={
    title: content && content.i18n.title,
    content: content && content.i18n.content,
    picture: content && basePath + content.picture.formats[imgSizeMap[breakPoint]].url
  }

  return (
    <Wrapper className={classes.join(" ")}>
      <Article content={contacts.content} picture={{src:contacts.picture,  alt:"nosotros"}}/>
    </Wrapper>
  );
};

Component.displayName = "Contacts";

export default Component;
