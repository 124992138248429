import React from "react";
import styled from "styled-components";
import { breakpointsMap } from "../hooks/mediaQuery";

const Wrapper = styled.div`
  img {
    display: block;
    max-width: 100%;
    margin: auto;
  }
  display: flex;
  flex-direction: column;

  .textWrapper {
    font-size: 2rem;
    margin-bottom: 2rem;
    *:last-child {
      margin-bottom: 0;
    }
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    font-size:2.5rem;
    span{
      flex-grow:1;
      word-break: break-word;
    }
    img {
      flex-shrink:0;
      width: 60px;
      margin-right: 2rem;
    }
  }

  .organic {
    img {
      box-shadow: 0 2px 5px rgba(0,0,0,0.8);
      border-radius: ${({radii1, radii2})=>radii1 + " / " + radii2};     
      transform: rotateZ(${({rotation})=>rotation}deg);
    }
  }

  @media screen and (${breakpointsMap.sm}) {
    flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};

    .title {
      font-size:3rem;
    }

    .contentWrapper {
      flex-grow: 1;
      margin: 0;
      ${({ reverse }) => (reverse ? "margin-left:2rem" : "margin-right:2rem")}
    }
    .contentWrapper,
    .imageWrapper {
      width: 50%;
    }

  }
`;

const Component = ({
  className,
  image,
  title,
  content,
  icon,
  reverse = false,
  pictureCut = 'default'
}) => {
  const classes = ["MediaObject"];
  className && classes.push(className);

  const radii1 = Array.from(new Array(4), (a=>15 + Math.random()*30 + "%")).join(" ");
  const radii2 = Array.from(new Array(4), (a=>5 + Math.random()*20 + "%")).join(" ");
  const rotation = 3 - (Math.random() * 6)

  return (
    <Wrapper className={classes.join(" ")} reverse={reverse} radii1={radii1} radii2={radii2} rotation={rotation}>
      <div className="contentWrapper">
        <h2 className="title">
          {icon && <img src={icon} alt="" />}
          <span>
            {title}
          </span>
        </h2>
        <div className="textWrapper">
          {content}
        </div>
      </div>
      <div className={`imageWrapper ${pictureCut}`}>
        <img src={image} alt={title} />
      </div>
    </Wrapper>
  );
};

Component.displayName = "MediaObject";

export default Component;
