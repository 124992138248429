import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import useI18n, {useI18Nfetch} from "../hooks/i18n";
import { ReactComponent as Back } from "../assets/back.svg";
import {breakpointsMap} from '../hooks/mediaQuery'

const Wrapper = styled.div`
  ul {
    font-size: 2rem;    
    margin: auto;
    display: flex;
    width: 100%;    
  }

  a {
    text-decoration: none;
    color: #444;
    padding: 1em 0.5em;
    display: block;
    font-weight: 400;
    line-height: 1;
    &:hover,
    &:focus {
      background-color: rgba(255, 255, 255, 0.5);
      outline: none;
    }
    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }
    span {
      display: block;
      margin-bottom: -3px;
      border-bottom: 3px solid transparent;
    }

    .iconButton {
      display: flex;
      align-items: center;
    }

    &.active {
      span {
        border-bottom: 3px solid #ffba00;
      }
    }
  }

  @media screen and (${breakpointsMap.sm}) {
    a {
      padding: 1em;
    }
  }
`;

const Component = ({ className, backOnly = false }) => {
  const classes = ["NavigationMenu"];
  className && classes.push(className);
  const menuItems = useI18Nfetch('nav-menus')||[];
  const lang = useI18n();

  return (
    <Wrapper className={classes.join(" ")}>
      <ul>
        {backOnly ? (
          <NavLink exact to={{pathname: "/", search:"?lang=" + lang }}>
            <span className="iconButton">
              <Back /> Back
            </span>
          </NavLink>
        ) : (
          menuItems.map(item => (
            <li key={item.path}>
              <NavLink exact to={{pathname: item.path, search:"?lang=" + lang }}>
                <span>{item.i18n.label}</span>
              </NavLink>
            </li>
          ))
        )}
      </ul>
    </Wrapper>
  );
};

Component.displayName = "NavigationMenu";

export default Component;
