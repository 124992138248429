import styled from 'styled-components'
import React from 'react'

const Wrapper = styled.div`
  background-color: rgba(255,255,255,0.8);
`;

const Content = styled.div`
  max-width: 1000px;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
  min-height:${({fullheight})=>fullheight?'100vh':'auto'};
`

export default ({className, children, fullHeight = false})=><Wrapper className={`PageMainContent ${className}`}><Content fullheight={fullHeight} className='PageMainContentWrapper'>{children}</Content></Wrapper>