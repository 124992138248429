import MD from "react-markdown";
import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
  .markDown {
    background-color: rgba(255,255,255,0.8);
    font-size: 2rem;
  }
  .markDown a {
    color: #add461;
    font-weight: bold;

    border-bottom: 2px solid transparent;

  }
  .markDown a:hover,
  .markDown a:focus {
    border-bottom-color: #ffba00;
  }

  p{
    margin-bottom: 1em;
  }
`;

const Component= ({className, content, target}) => {

  const classes=['MarkDown']
  className && classes.push(className)

  return (
    <Wrapper className={classes.join(' ')}>
       <MD
        className='markDown'
          source={content}
          renderers={{
            link: props => (
              <a href={props.href} target={target}>
                {props.children}
              </a>
            )
          }}
        />
    </Wrapper>
  )
}

Component.displayName='MarkDown'

export default Component