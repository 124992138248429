import React from 'react';
import Mission from '../Mission';
import Header from "../Header";
import NavMenuStickyWrapper from "../NavMenuStickyWrapper";
import PageMainContent from '../PageMainContent';
import {useI18Nfetch} from "../../hooks/i18n";

const Component= ({className,...props}) => {
  const mission = useI18Nfetch('mission');
  const classes=['Mission']
  className && classes.push(className)

  return (
    <>
      <Header compact />
      <NavMenuStickyWrapper />
      <PageMainContent fullHeight>
        <Mission description={mission && mission.i18n.Long_description} target='_blank'/>
      </PageMainContent>
    </>
  )
}

Component.displayName='Mission'

export default Component