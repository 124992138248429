import books from '../assets/images/books.png';
import shoppingBags from '../assets/images/shoppingBags.png';
import hourglass from '../assets/images/hourglass.png';
import homeHart from '../assets/images/homeHart.png';
import laptop from '../assets/images/laptop.png';
import leaves from '../assets/images/leaves.png';
import people from '../assets/images/people.png';
import recycle from '../assets/images/recycle.png';
import web from '../assets/images/web.png';
import peopleNetwork from '../assets/images/peopleNetwork.png';

const iconsMap={
  books,
  hourglass,
  homeHart,
  laptop,
  leaves,
  people,
  peopleNetwork,
  shoppingBags,
  recycle,
  web
}

export default iconsMap;