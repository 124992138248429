import React from "react";
import { basePath } from "./config";

const cache={}


export const useFetch = collectionName => {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    const myAbortController = new AbortController();
    fetch(basePath + "/" + collectionName,{
      signal: myAbortController.signal
    })
      .then(result => result.json())
      .then(json => {
        cache[collectionName]=json;
        setData(json)
      });

    return ()=>{
      myAbortController.abort(); 
    }

  }, [collectionName]);


  if(cache[collectionName]){
    return cache[collectionName]
  }

  return data;
};

export const useActivities = () => useFetch("activities");
export const useAboutUs = () => useFetch("about-us");
export const useMission = () => useFetch("mission");
export const useHero = () => useFetch("hero");
