import React from "react";
import styled from "styled-components";
import Button from "components/Button";
import FacebookBtn from "components/FacebookBtn";
import GoogleBtn from "components/GoogleBtn";
import Separator from "components/Seaparator";

const Wrapper = styled.div`
  font-size: 1.8em;
  padding: 2rem;
  max-width: 400px;
  margin: auto;
  h2 {
    margin: 3rem 0;
  }

  form {
    border-top: 4px solid #99ce44;
    padding: 2rem;
    box-shadow: 0 2px 14px 1px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
  }

  section {
    button {
      margin-bottom: 1em;
    }
  }
`;

const Component = ({ className, onLogin }) => {
  const [details, setDetails] = React.useState({});

  const classes = ["LoginForm"];
  className && classes.push(className);

  function onChange(e) {
    details[e.target.name] = e.target.value;
    setDetails(details);
  }
  function handleSubmit(e) {
    e.preventDefault();
    onLogin({
      type: "local",
      details
    });
  }

  function handleSocials(social) {
    return () => {
      onLogin({
        type: "social",
        details: social
      });
    };
  }

  return (
    <Wrapper className={classes.join(" ")}>
      <h2>Welcome stranger!</h2>
      <form onSubmit={handleSubmit}>
        <section>
          <FacebookBtn fullWidth onClick={handleSocials("facebook")} />
          <GoogleBtn fullWidth onClick={handleSocials("google")} />
        </section>
        <Separator>or</Separator>
        <section>
          <Field
            name="email"
            onChange={onChange}
            value={details.email}
            type="text"
            label="Email address"
          />
          <Field
            name="password"
            onChange={onChange}
            value={details.password}
            type="password"
            label="Password"
          />
          <Button fullWidth type="submit">
            Submit
          </Button>
        </section>
      </form>
    </Wrapper>
  );
};

Component.displayName = "LoginForm";

export default Component;

const Field = styled(
  ({ label, name, value, type = "text", className, placeholder, onChange }) => {
    return (
      <label className={className}>
        <span className="label">{label}:</span>
        <input
          onChange={onChange}
          type={type}
          name={name}
          defaultValue={value}
          placeholder={placeholder}
        />
      </label>
    );
  }
)`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  span {
    font-weight: 400;
  }
  input {
    border: 1px solid #ccc;
    padding: 0.5em;
    background-color: #f5f5f5;
    outline: none;
    font-size: 1em;
    &:focus {
      background-color: #d0f5f5;
    }
  }
`;
