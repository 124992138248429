import React from "react";
import styled from "styled-components";
import Article from './Article'

const Wrapper = styled.div``;

const Component = ({ className, description, target = "_self" }) => {
  const classes = ["Mission"];
  className && classes.push(className);

  return description ? (
    <Wrapper className={classes.join(" ")}>
      <Article content = {description} target={target}/>
    </Wrapper>
  ) : null;
};

Component.displayName = "Mission";

export default Component;
