import React from "react";
import styled from "styled-components";
import useLang from "../hooks/i18n";
import langIcon from '../assets/Language-Icons/icon20x24px-exported-transparent.png';
import {breakpointsMap} from '../hooks/mediaQuery'
import {
  useHistory
} from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  img{
    opacity:.6;
  }
  select{
    width: 0px;
    height: 0px;
    overflow: hidden;
    border: none;
    background-color: transparent;
    font-size: 1.4rem;
    -webkit-appearance: none;
    position: absolute;
    padding: 1rem 1rem;
    margin:0;
    outline:none;
    &:focus{
      background-color: rgba(0,0,0,0.1);
    }
  }
  
  @media screen and (${breakpointsMap.sm}) {
    select {
      padding: 1rem .5rem;
      position: static;
      width: auto;
      height: auto;
      overflow: visible;
    }
  }
`;

const languages = [
  {id:"en", label:"English"},
  {id:"es", label:"Castilian"},
  {id:"ca", label:"Catalan"}
];

const Component = ({ className }) => {
  const lang = useLang();
  const history = useHistory();

  const classes = ["LangPicker"];
  className && classes.push(className);
  function handleChange(e) {        
    history.push(`?lang=${e.target.value}`)
  }
  return (
    <Wrapper className={classes.join(" ")}>
      <label htmlFor='languagePicker'>
        <img alt='language' src={langIcon}/>
      </label>
      <select id="languagePicker" onChange={handleChange} value={lang}>
        {languages.map(l => (
          <option key={l.id} value={l.id}>{l.label}</option>
          ))}
      </select>
    </Wrapper>
  );
};

Component.displayName = "LangPicker";

export default Component;
