import React from "react";
import styled from "styled-components";
import "leaflet/dist/leaflet.css";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import activityIcons from "../lib/activityIcons";
import { breakpointsMap } from "../hooks/mediaQuery";
import useLS from "hooks/useStorage";

const Wrapper = styled.div`
  padding: 50px 0px;
  background-color: white;
  .leaflet-container {
    height: 40vh;
    max-width: 1000px;
    margin: auto;
    position: relative;
    z-index: 1;
  }

  .leaflet-tile-container {
    filter: grayscale(0.5);
  }

  .map_activityIconMarker {
    border-radius: 30px;
    box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.5);
    border: 2px solid white;
    img {
      display: block;
      width: 100%;
    }
  }

  @media screen and (${breakpointsMap.sm}) {
    .leaflet-container {
      height: 30vh;
    }
  }
`;

const center = {
  lat: 39.6138,
  lng: 2.9117
};

const zoom = 13;

// const url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const url = "https://tile-c.openstreetmap.fr/hot/{z}/{x}/{y}.png";
const attribution =
  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

const tileLayerOptions = {
  url,
  attribution,
  maxNativeZoom: 19 // OSM max available zoom is at 19.
  // maxZoom: 20 // Match the map maxZoom, or leave map.options.maxZoom undefined.
};

const mapIcons = Object.keys(activityIcons).reduce((accumulator, value) => {
  accumulator[value] = L.divIcon({
    className: `map_activityIconMarker`,
    iconSize: [40, 40],
    iconAnchor: [20, 20],
    html: `
      <div class="map_iconWrapper">
        <img src="${activityIcons[value]}" alt="${value}" />
      </div>
    `
  });

  return accumulator;
}, {});

const Component = ({ className, markers, onMarkerClick }) => {
  const classes = ["MallorcaMap"];
  const mapRef = React.useRef();
  className && classes.push(className);
  const [auth] = useLS("auth");

  console.log("auth", auth)

  function handleClick(m) {
    return () => {
      onMarkerClick(m);
    };
  }

  const bounds = markers.length
    ? new L.LatLngBounds(markers.map(m => [m.loc.lat, m.loc.lng]))
    : undefined;

  return (
    <Wrapper className={classes.join(" ")}>
      {auth && (<h2>Hello {auth.user.username}</h2>)}
      <Map
        ref={mapRef}
        center={[center.lat, center.lng]}
        zoom={zoom}
        bounds={bounds}
        boundsOptions={{ padding: [10, 10] }}
        useFlyTo={true}
      >
        <TileLayer {...tileLayerOptions} />
        {markers.map(m => {
          return (
            <Marker
              key={`${m.loc.lat}${m.loc.lng}`}
              icon={mapIcons[m.type]}
              title={m.name}
              position={[m.loc.lat, m.loc.lng]}
              onClick={handleClick(m)}
            >
              <Popup>{m.popup}</Popup>
            </Marker>
          );
        })}
      </Map>
    </Wrapper>
  );
};

Component.displayName = "MallorcaMap";

export default Component;
