import React from "react";

import PageMainContent from "../PageMainContent";
import SimplePageBackOnly from "components/pages/SimplePageBackOnly";
import LoginForm from "components/LoginForm";
import useLS from "hooks/useStorage";
import {basePath} from 'lib/config'

const endPointUrl = {
  social: `${basePath}/connect/`,
  local: `${basePath}/auth/`
};
const Component = ({ className, history }) => {
  const [auth, setAuth] = useLS("auth");

  console.log("auth", auth)
  if(auth){
    history.push("/")
    return null
  }
  const classes = ["Contacts"];
  className && classes.push(className);

  function onLogin({ type, details }) {

    switch (type) {
      case "local":
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            identifier: details.email,
            password: details.password
          })
        };
        const url = endPointUrl[type] + "local";

        fetch(url, options)
          .then(response => {
    
            if (response.ok){
              return response.json ? response.json() : response
            }else{
              // setErrorMessage(response.statusText)
              console.warn(response,response.statusText)
              return undefined
            }
          })
          .then(data => {
            setAuth(data);
          })
          .catch(err => console.log(err));
                  
        break;

      case "social":
        window.location.href=endPointUrl[type] + details;
        break;
      default:
    }

  }
  return (
    <>
      <SimplePageBackOnly>
        <PageMainContent fullHeight>
          <LoginForm onLogin={onLogin} />
        </PageMainContent>
      </SimplePageBackOnly>
    </>
  );
};

Component.displayName = "Contacts";

export default Component;
