import React from "react";
import Contacts from "../Contacts";
import Header from "../Header";
import NavMenuStickyWrapper from "../NavMenuStickyWrapper";
import PageMainContent from "../PageMainContent";
import { useI18Nfetch } from "../../hooks/i18n";

const Component = ({ className, ...props }) => {
  const contacts = useI18Nfetch("contacts");
  const classes = ["Contacts"];
  className && classes.push(className);

  return (
    <>
      <Header compact />
      <NavMenuStickyWrapper />
      <PageMainContent fullHeight>
        {contacts && <Contacts data={contacts} />}
      </PageMainContent>
    </>
  );
};

Component.displayName = "Contacts";

export default Component;
