import React from 'react';
import Home from './components/pages/Home'
import NotFound from './components/pages/NotFound'
import Contacts from './components/pages/Contacts'
import Mission from './components/pages/Mission'

import ScrollToTop from 'components/ScrollToTop'
import FixedBG from 'components/FixedBG'
import Login from 'components/pages/Login'
import SocialConnect from 'components/pages/SocialConnect'

import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <div className="App">
        <FixedBG/>
        <main>
          <Switch>
            <Route path="/" exact component={Home}  />
            <Route path="/contacts" component={Contacts}  />
            <Route path="/mission" component={Mission}  />
            <Route path="/login" component={Login}  />
            <Route path="/connect/:socialId" component={SocialConnect} />
            <Route path="/" component={NotFound} />
            {/* <Route path="/projects/:id" component={Projects}  /> */}
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;
