import React from 'react';
import styled from 'styled-components';
import TopBar from './TopBar';

const Wrapper = styled.div`
  position: sticky;
  top: 60px;
  z-index:9;

  .contentWrapper {
    width: 100%;
    left: 0;
    position: absolute;
    bottom: 0px;

    .TopBar {
      flex-grow:1;
    }
  }
`;

const Component= ({className,...props}) => {

  const classes=['NavMenuStickyWrapper']
  className && classes.push(className)

  return (
    <Wrapper className={classes.join(' ')}>
      <div className="contentWrapper">
        <TopBar/>
      </div>
    </Wrapper>
  )
}

Component.displayName='NavMenuStickyWrapper'

export default Component