import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.button`
  border: 1px solid rgba(0,0,0,0.2);
  background-color:#99ce44;
  color: white;
  display: inline-flex;
  
  font-size: inherit;
  border-radius: 3px;
  padding: 0.5em 1em;
  ${({fullWidth})=>fullWidth?`width:100%`:'auto'};
  justify-content: center;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus{
    box-shadow: 0 2px 7px 0px rgba(0,0,0,0.3);
  }
`;

const Component= ({className,children, ...props}) => {

  const classes=['Button']
  className && classes.push(className)

  return (
    <Wrapper type='button' className={classes.join(' ')} {...props}>
      {children}
    </Wrapper>
  )
}

Component.displayName='Button'

export default Component