import React from "react";
import styled from 'styled-components';
import Button from 'components/Button';
import { ReactComponent as Logo } from 'assets/socials/facebook.svg';

export default styled(Button).attrs(({className, children, ...props})=>({
  className:[className,'FacebookBtn'].join(' '),
  children:(<><Logo/>Facebook</>),
  props,
}))`
  background:linear-gradient(315deg, #0097F6 0%, #005EEA 100%);
  svg{
    margin-right: 0.5em;
    width: 1em;
  }
`;
