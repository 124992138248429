import React from 'react';
import styled from 'styled-components';
import { basePath } from "../lib/config";
const bg= basePath + '/uploads/stefan-kunze-eWFdaPRFjwE-unsplash_b50e40bf94_f40d1a230d.jpeg'

const Wrapper = styled.div`
    background-image: url(${bg});
    background-size: cover;
    background-position: center;
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;

    z-index: -1;
`;

const Component= ({className,...props}) => {

  const classes=['FixedBG']
  className && classes.push(className)

  return (
    <Wrapper className={classes.join(' ')} />      
  )
}

Component.displayName='FixedBG'

export default Component