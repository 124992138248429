import React from 'react';
import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';
import Box from "./Box";
import iconsMap from '../lib/activityIcons';
import { breakpointsMap } from "../hooks/mediaQuery";

const Wrapper = styled(Box)`

  background-color: #fff;
  .contentWrapper {
    max-width: 1000px;
    margin: auto;
  }

  .activities {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li{
      margin: 3rem;
      width: 100px;
    }

    a{
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        width: 80px;
      }
      span{
        font-size: 1.2rem;
        margin: 1rem;
        text-align: center;
      }
    }
  }

  @media screen and (${breakpointsMap.sm}) {
    .activities {
      li{
        width: 190px;
      }
      a{     
        img{
          width: 120px;
        }
      }
    }
  }

`;

const Component= ({className, data:activities=[], title, subtitle}) => {

  const classes=['ActivitiesQuickLinks']
  className && classes.push(className)

  return (
    <Wrapper className={classes.join(' ')} p='5 3'>
      <div className='contentWrapper'>
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
        <ul className="activities">
          {activities.map(a=>(
            <li key={a.id}>
              <Link to={{hash:`#${a.id}`}}>
                <img src={iconsMap[a.Icon]} alt=""/>
                <span>{a.i18n.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Wrapper>
  )
}

Component.displayName='ActivitiesQuickLinks'

export default Component