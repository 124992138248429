import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding:${({p})=>p && p.toString().split(' ').map(p2=>`${p2}rem`).join(' ')};
  margin:${({m})=>m && m.toString().split(' ').map(m2=>`${m2}rem`).join(' ')};  
  max-width:${({maxWidth})=>maxWidth};
`;

const Component= ({className, children, ...props}) => {

  const classes=['Box']
  className && classes.push(className)

  return (
    <Wrapper className={classes.join(' ')} {...props}>
      {children}
    </Wrapper>
  )
}

Component.displayName='Box'

export default Component