import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  a {
    background-color: #add461;
    padding: .5em 1em;
    border-radius: 40px;
    color: white;
    font-weight: 400;
    cursor: pointer;
    font-size: 2rem;
    display: inline-block;    
  }
`;

const Component= ({className, href, label, target}) => {

  const classes=['ExternalLink']
  className && classes.push(className)

  return (

    <Wrapper className={classes.join(' ')}>
      <a href={href} target={target}>{label}</a>
    </Wrapper>
  )
}

Component.displayName='ExternalLink'

export default Component