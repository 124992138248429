import React from "react";
import styled from "styled-components";

import Header from "../Header";
import NavMenuStickyWrapper from "../NavMenuStickyWrapper";
import PageMainContent from "../PageMainContent";
import Activities from "../Activities";
import ActiviesQuickLinks from "../ActivitiesQuickLinks";
import MallorcaMap from "../MallorcaMap";

import Mission from "../Mission";
import { useI18Nfetch } from "../../hooks/i18n";
import { useFetch } from "../../lib/strapi";

import { ReactComponent as Forum } from "../../assets/images/forum.svg";
import { ReactComponent as Telegram } from "../../assets/images/telegram.svg";




const Wrapper = styled.div`
  > .PageMainContent {
    .PageMainContentWrapper {
      position: relative;
      margin: -10px auto;
    }
  }
`;

const Popup = styled.div`
  text-align: center;
  width: 150px;
  a {
    display: flex;
    align-items: center;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: #efefef;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }
`;

const Component = ({ className, ...props }) => {
  const activitiesList = useI18Nfetch("activities");
  const mission = useI18Nfetch("mission");
  const liveActivities = useFetch("live-activities") || [];

  const markers = liveActivities.map(la => ({
    loc: {
      lat: la.lat,
      lng: la.lng
    },
    name: la.name,
    type: la.activity && la.activity.Icon,
    popup: (
      <Popup>
        <p>{la.name}</p>
        <a href={la.url} target="_blank" rel="noopener noreferrer">
          <Forum />
          Forum
        </a>
        {la.Telegram_group && (
          <a href={la.Telegram_group} target="_blank" rel="noopener noreferrer">
            <Telegram />
            Telegram group
          </a>
        )}
      </Popup>
    )
  }));

  function handleMarkerClick(m) {
    // I don't need this right now, the popup element handles it for me
    console.log(m);
  }

  const classes = ["Home"];
  className && classes.push(className);

  const missionData = mission && mission.i18n;

  return (
    <Wrapper>
      <Header />
      <NavMenuStickyWrapper />
      <ActiviesQuickLinks
        data={activitiesList}
        title={missionData && missionData.title}
        subtitle={missionData && missionData.subtitle}
      />
      <PageMainContent>
        <Mission description={missionData && missionData.description} />
      </PageMainContent>
      <MallorcaMap markers={markers} onMarkerClick={handleMarkerClick} />
      <Activities data={activitiesList} />
    </Wrapper>
  );
};

Component.displayName = "Home";

export default Component;
