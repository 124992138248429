import React from "react";
import styled from 'styled-components';
import Button from 'components/Button';
import { ReactComponent as Logo } from 'assets/socials/google.svg';

export default styled(Button).attrs(({className, children, ...props})=>({
  className:[className,'GoogleBtn'].join(' '),
  children:(<><Logo/>Google</>),
  props,
}))`
  background:linear-gradient(315deg, #fff 0%, #f0f0f0 100%);
  color: #005EEA;
  svg{
    margin-right: 0.5em;
    width: 1em;
  }
`;
