import React from "react";
import { useLocation } from "react-router-dom";
import { useFetch } from "../lib/strapi";

const useI18n = () => {
  const defaultLang = "es";
  const location = useLocation();
  const [lang, setLang] = React.useState(defaultLang);

  React.useEffect(() => {
    const lang =
      new URLSearchParams(location.search).get("lang") || defaultLang;
    setLang(lang);
  }, [location.search]);

  return lang;
};

export default useI18n;

export const useI18Nfetch = collection => {
  const lang = useI18n();

  let results = undefined;
  const data = useFetch(collection);

  if (data) {
    if (Array.isArray(data)) {
      results = data.map(a => {
        const { i18n, ...rest } = a;
        return {
          ...rest,
          i18n: { ...i18n.find(d => d.language === lang) }
        };
      });
    } else {
      const { i18n, ...rest } = data;
      results = {
        ...rest,
        i18n: { ...i18n.find(d => d.language === lang) }
      };
    }
  }

  return results;
};
