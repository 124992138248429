import React from 'react';
import styled from 'styled-components'

export default styled(({className, children})=><div className={className}><span>{children}</span></div>)`
  display:flex;
  align-items: center;
  span {
    flex-shrink:0;
    padding:1em;
  }
  &:after,
  &:before{
    flex-grow:1;
    content:"";
    height: 1px;
    background-color: rgba(0,0,0,0.1);
  }
`