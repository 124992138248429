import React from 'react';
import styled from 'styled-components';
import NavMenu from './NavigationMenu';
import LangPicker from './LangPicker'

const Wrapper = styled.div`
  background-color: #f0f0f0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

  .topBarWrapper{
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 1040px;
    margin: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .LangPicker {
    margin-right:2rem;
  }
`;

const Component= ({className,...props}) => {

  const classes=['TopBar']
  className && classes.push(className)

  return (
    <Wrapper className={classes.join(' ')}>
      <div className="topBarWrapper">
        <NavMenu/>
        <LangPicker/>
      </div>
    </Wrapper>
  )
}

Component.displayName='TopBar'

export default Component