import React from "react";

//todo: move this outside the hook
const breakpoints = [
  { key: "xs", value: "max-width: 599px" },
  { key: "sm", value: "min-width: 600px" },
  { key: "md", value: "min-width: 900px" }
];

export const imgSizeMap = {
  xs: "small",
  sm: "medium",
  md: "large"
};

export const boxPaddingMap = {
  xs: '8 3',
  sm: '8 5',
  md: 8
};

export const breakpointsMap = breakpoints.reduce((prev, curr) => {
  prev[curr.key] = curr.value;
  return prev;
}, {});

function getMq() {
  const matches = breakpoints.map(bp => ({
    ...bp,
    matches: window.matchMedia(`(${bp.value})`).matches
  }));

  const res =  matches.reduce((prev, curr) => {
    return curr.matches ? curr : prev;
  }, breakpoints[0]);

  return res.key
}


export default () => {
  const [media, setMedia] = React.useState(getMq());
  
  function setValue(){
    setMedia(getMq())
  }

  React.useEffect(() => {
    window.addEventListener("resize", setValue);

    return ()=>{
      window.removeEventListener("resize", setValue)
    }
  }, []);

  return media;
};
