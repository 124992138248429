import React from 'react';
import Header from "../Header";
import NavMenuStickyWrapper from "../NavMenuStickyWrapper";
import styled from 'styled-components';

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 100vh;
  img {
    max-width: 100%;
  }
`;

const Component= ({className,...props}) => {

  const classes=['NotFound']
  className && classes.push(className)

  return (
    <>
      <Header compact />
      <NavMenuStickyWrapper />
      <ImageWrapper className={classes.join(' ')}>
        <img alt="404 - page not found" src='https://cdn.dribbble.com/users/1651691/screenshots/5336717/404_v2.png'/>
      </ImageWrapper>
    </>
  )
}

Component.displayName='NotFound'

export default Component

