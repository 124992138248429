import {basePath} from 'lib/config'
import useLS from "hooks/useStorage";

const endPointUrl = {
  social: `${basePath}/connect/`,
  local: `${basePath}/auth/`
};

export default({match, location, history})=>{  
  const [auth, setAuth] = useLS("auth");

  if(auth){
    history.push("/")
    return null;
  }

  const requestURL = `${endPointUrl.local}${match.params.socialId}/callback${location.search}`;
  const options = { 
    method: 'GET',
    headers:{
      "Content-Type": "application/json"
    }
  }
  
  // if (token) {
  //   options.headers = Object.assign({
  //     Authorization: `Bearer ${token}`,
  //   }, options.headers);
  // }

  fetch(requestURL, options)
    .then(response => {

      if (response.ok){
        return response.json ? response.json() : response
      }else{
        // setErrorMessage(response.statusText)
        console.warn(response,response.statusText)
        return undefined
      }
    })
    .then(data => {
      setAuth(data);
    })
    .catch(err => console.log(err));

  return null;
}
