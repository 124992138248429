import React from "react";
import styled from "styled-components";
import MarkDown from "./MarkDown";
import { breakpointsMap } from "../hooks/mediaQuery";

const Wrapper = styled.div`
  .contentWrapper {
    overflow: hidden;
    position: relative;
    padding: 5rem 3rem;
    &::first-letter {
      color: #ffba00;
      font-weight: 400;
      font-size: 6rem;
      float: left;
      line-height: 0.5;
    }

    .image{
      border-radius: 5px;
      float: right;
      padding: 10px;
      margin: 10px;
      transform: rotateZ(2deg);
      box-shadow: 1px 2px 4px rgba(0,0,0,0.3);
      width: 50%;
    }
  }

  @media screen and (${breakpointsMap.sm}) {
    .contentWrapper {
      padding: 8rem;
    }
  }
`;

const Component = ({ className, content, picture, target = "_self" }) => {
  const classes = ["Article"];
  className && classes.push(className);

  return content ? (
    <Wrapper className={classes.join(" ")}>
      <div className="contentWrapper">
        {picture && <img src={picture.src} alt={picture.alt} className="image"/>}
        <MarkDown {...{ content, target }} />
      </div>
    </Wrapper>
  ) : null;
};

Component.displayName = "Article";

export default Component;
