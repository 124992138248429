import React from "react";

import Header from "../Header";
import NavMenuStickyWrapper from "../NavMenuStickyWrapper";
import PageMainContent from '../PageMainContent';

import styled from "styled-components";


const Component = ({ className, children }) => {
  const classes = ["SimplePageBackOnly"];
  className && classes.push(className);

  return (
    <>
      <Header compact />
      <NavMenuStickyWrapper />
      <PageMainContent fullHeight>
      {children}
      </PageMainContent>
    </>    
  );
};

Component.displayName = "SimplePageBackOnly";

export default Component;
